import { Link, Outlet } from '@remix-run/react';
import { Container } from '~/components/container';
import { Logo } from '~/components/logo';
import { LegalLinks } from '~/features/legal/legal-links';
import { cn } from '~/utils/cn';
import { ROUTES } from '~/utils/routes';

function AuthLayout() {
  return (
    <div className="bg-backgrund min-h-[100vh] w-full flex">
      <Container className="flex-1 flex flex-col">
        <div className="h-16 flex items-center">
          <Link to={ROUTES.LANDING.HERO}>
            <Logo />
          </Link>
        </div>
        <div className="flex-1 flex items-center">
          <div className="max-w-[440px] w-full mx-auto">
            <Outlet />
          </div>
        </div>
        <LegalLinks />
      </Container>
      <div className="flex-1 hidden lg:block">
        <img
          src="/img/auth-hero.jpg"
          alt="Creator"
          className={cn('w-full h-full object-cover')}
        />
      </div>
    </div>
  );
}

export const shouldRevalidate = () => false;

export default AuthLayout;
