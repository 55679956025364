import { Link } from '@remix-run/react';
import { ROUTES } from '~/utils/routes';

export function LegalLinks() {
  return (
    <div className="h-16 flex items-center gap-4 text-sm text-muted-foreground font-medium">
      <Link to={ROUTES.LEGAL.TERMS_OF_SERVICE}>Terms of Service</Link>
      <span className="opacity-30">•</span>
      <Link to={ROUTES.LEGAL.PRIVACY_POLICY}>Privacy Policy</Link>
    </div>
  );
}
